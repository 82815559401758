import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Listings from "./pages/Listings";
import Documents from "./pages/Documents";
import Holding from "./pages/Holding";
import ListingDetail from "./pages/ListingDetail";
import Authentication from "./pages/Authentication";
import { routes } from "./routes/routes";
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";

const oktaConfig = {
  issuer:
    "https://" +
    window.env.REACT_APP_OKTA_HOST +
    "/oauth2/" +
    window.env.REACT_APP_OKTA_ORGANISATION +
    "/",
  clientId: window.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + "/login/callback",
  scopes: ["openid", "profile"],
};
const oktaAuth = new OktaAuth(oktaConfig);

const App = () => {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  return (
    <>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Routes>
          <Route path={routes.listing} element={<Listings />} />
          <Route path={routes.listingDetail} element={<ListingDetail />} />
          <Route path={routes.documents} element={<Documents />} />
          <Route path={routes.holding} element={<Holding />} />
          <Route path={routes.auth} element={<Authentication />} />
          <Route path="/login/callback" element={<LoginCallback />} />
        </Routes>
      </Security>
    </>
  );
};

export default App;

export { oktaConfig };
