import { render, screen } from "@testing-library/react";
import Authentication from "./Authentication";
import { BrowserRouter } from "react-router-dom";
import { Security } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";

const oktaConfig = {
  issuer:
    "https://" +
    process.env.REACT_APP_OKTA_HOST +
    "/oauth2/" +
    process.env.REACT_APP_OKTA_ORGANISATION +
    "/",
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + "/login/callback",
  scopes: ["openid", "profile"],
};
const oktaAuth = new OktaAuth(oktaConfig);
const restoreOriginalUri = (_oktaAuth, originalUri) => {};

test("check rendering of sign in widget", async () => {
  render(
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <BrowserRouter>
        <Authentication onSuccess={() => {}} onError={() => {}} />
      </BrowserRouter>
    </Security>,
  );
  expect(screen.getByAltText("Zero13 Logo")).toBeInTheDocument();
});
