import React, { useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import BrandWelcomeImage from "../../assets/images/welcome-message.png";
import { oktaConfig } from "../../App";
import "@okta/okta-signin-widget/css/okta-sign-in.min.css";

const OktaSignInWidget = ({ onSuccess, onError }) => {
  const widgetRef = useRef();
  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }

    const widget = new OktaSignIn({
      ...oktaConfig,
      logo: BrandWelcomeImage,
    });

    // Search for URL Parameters to see if a user is being routed to the application to recover password
    var searchParams = new URL(window.location.href).searchParams;
    widget.otp = searchParams.get("otp");
    widget.state = searchParams.get("state");
    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);

    return () => widget.remove();
  }, [onSuccess, onError]);

  return (
    <div>
      <div className="md:absolute md:-top-1/3" ref={widgetRef} />
    </div>
  );
};

export default OktaSignInWidget;
