import { jwtDecode } from 'jwt-decode'

export const getAPIHeader = () => {
    let header = {
        "Content-Type": "application/json",
    }
    try {
        let tokenResult = localStorage.getItem("okta-token-storage")
        if (tokenResult) {
          let token = JSON.parse(tokenResult)
          if(token.accessToken) {
            header["authorization"] =  `Bearer ${token.accessToken.accessToken}`
          }
        }
        return header;
    } catch (error) {
        return header
    }
}


export const parseJWTDetail = (type) => {
  try {
    let dataID = ""
    let tokenResult = localStorage.getItem("okta-token-storage")
    if (tokenResult) {
      let token = JSON.parse(tokenResult)
      if(token.accessToken) {
        const data = jwtDecode(token.accessToken.accessToken)
        if(type === "account") {
          dataID = data["account-id"]
        } else if(type === "account") {
          dataID = data["group-id"]
        } else {
          dataID = data["participant-id"]
        }
      }
    }
    return dataID;
  } catch (error) {
      return ""
  }
}


export const getUsername = () => {
  try {
    let dataID = ""
    let tokenResult = localStorage.getItem("okta-token-storage")
    if (tokenResult) {
      let token = JSON.parse(tokenResult)
      if(token.idToken) {
        return token.idToken.claims.name
      }
    }
    return dataID;
  } catch (error) {
      return ""
  }
}
