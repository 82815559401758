export const getExchangeName = () =>
  JSON.parse(localStorage.getItem("okta-token-storage"))?.accessToken?.claims
    ?.exchange || "SECDEX";

export const api = {
  EXCHANGE: getExchangeName,
  METHOD_INSTRUMENT_SEGMENTS: "/instrument/segments",
  METHOD_INSTRUMENT_TYPES: "/instrument/types",
  METHOD_DOCUMENT_TYPES: "/document/types",
};
